.search-bar-container {
    margin-right: 10px;
    flex: 1;
}

.search-input {
    border-radius: 5px;
    height: 32px;
    padding-left: 5%;
    margin-right: 2px;
    flex: 1;
    border: 0;
}

.search-button {
    border-radius: 5px;
    height: 34px;
    box-sizing: border-box;
    border: 0;
}

.error-message {
    color: rgb(84, 33, 33);
    margin-top: 20px;
    font-size: 0.9rem;
  }
  