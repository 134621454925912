/* Define keyframes for fade-in and slide-up animation */
@keyframes fadeInSlideUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Container for the list of repositories */
.repo-list-container {
    margin-top: 20px;
    margin-bottom: 30px;
    padding: 0 20px;
    width: 800px;
    margin-left: auto;
    margin-right: auto;
    background-color: #009eb05d;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    animation: fadeInSlideUp 0.5s ease-out;
  }
  
  /* Individual repository item */
  .repo-item {
    padding: 15px;
    border-bottom: 1px solid #ddd;
    display: block;
  }
  
  .repo-item:last-child {
    border-bottom: none;
  }
  
  /* Repository name (clickable link) */
  .repo-name {
    font-size: 18px;
    font-weight: bold;
    text-decoration: none;
    color: #a1f9ff;
    display: block;
    margin-bottom: 5px;
  }

  .repo-description{
    font-size: 15px;
    color: #cbe3e7;
  }
  
  .repo-name:hover {
    text-decoration: underline;
  }
  
  /* Repository language */
  .repo-language {
    font-size: 14px;
    color: #a7ffe3;
    display: block;
    margin-top: 5px;
  }

  .highlighted {
    background-color: rgba(215, 252, 255, 0.387); 
    font-weight: bold;
  }

  