.App {
  text-align: center;
}

.App-header {
  background-color: #003840;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.search-filter-container {
  background-color: #768c8f;
  display: flex;
  width: 250px;
  flex-direction: row;
  border-radius: 30px;
  align-items: center;
  color: white;
  padding: 20px 30px;
}

  /* Empty message when no repositories are available */
  .repo-empty-message {
    text-align: center;
    color: #666;
    font-size: 16px;
    padding: 20px;
  }

  .loading-gif {
    margin-top: 30px;
    width: 100px; 
  }

  .logo-icon {
    height: 40px;
  }